import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import axios from '../api/axios';
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import OrderList from '../components/OrderList';
import { OrdersResponse } from '../@types/api-models';
import { useLocation } from 'react-router-dom';
import { useOrderBy } from '../hooks/OrderBy';

const Orders: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [ready, setReady] = useState<boolean | undefined>();
    const [offset, setOffset] = useState(0);
    const location = useLocation();
    const [orderBy, toggleOrderBy, orderByClassnames, restoreOrderBy] = useOrderBy({field: 'id', dir: 'desc'})

    const onScroll = () => setOffset(window.scrollY);

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['orders', currentPage],
        queryFn: async () => {
            const params = {
                _page: currentPage,
                _limit: ITEM_PER_PAGE,
                _order: orderBy == null ? undefined : orderBy.field + ':' + orderBy.dir,
            };
            const response = await axios.get<OrdersResponse>(
                '/api/v1/orders', {
                    params,
                    paramsSerializer: {
                        indexes: true, // use brackets with indexes
                    }
                }
            );
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const orders = response.data.data;
            return { orders, total, perPage }
        },
        placeholderData: keepPreviousData,
        enabled: !!ready,

    });

    useEffect(() => {
        window.removeEventListener('scroll', onScroll);

        if (location.state) {
            const { currentPage, scrollPosition, orderBy } = location.state;
            setCurrentPage(currentPage)
            restoreOrderBy(orderBy)
            setTimeout(() => {
                window.scrollTo(0, scrollPosition);
            }, (10));
        }

        setReady(true);
        window.addEventListener('scroll', onScroll, { passive: true });

        // clean up code
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    useEffect(() => {
        refetch();
    }, [orderBy])

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Pedidos</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <OrderList
                    orders={data? data.orders: []}
                    prevState={{currentPage, scrollPosition: offset, orderBy}}
                    toggleOrderBy={toggleOrderBy}
                    orderByClassnames={orderByClassnames}
                    />
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default Orders
