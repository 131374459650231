import React from 'react'
import useAuthContext from '../context/AuthContext'
import { Navigate, Outlet } from 'react-router-dom';

const GuestLayout: React.FC = () => {
    const { user } = useAuthContext();

    return (
        <>
        {(() => {
            if (user === undefined) {
                return <div className="skeleton w-full h-32"></div>;
            }

            if (user === null) {
                return <Outlet/>
            }

            return <Navigate to="/" />
        })()}
        </>
    )
    
}

export default GuestLayout
