import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import useAuthContext from '../context/AuthContext';

const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, errors, error, resetStatus } = useAuthContext(); 

    useEffect(() => {
        // I'll run when the component is mounted
        // Setting the initial state for this component
        return () => {
          // all the clean-ups related to this component
          // I'll run when the component is unmounted
            setEmail("");
            setPassword("");
            resetStatus();
        }
    
      }, []);

    
    const handleLogin = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        login({email, password})
    }

    return <section className="bg-[#F4F7FF] py-20 lg:py-[120px]">
        <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                    <div
                    className="
                        relative
                        mx-auto
                        max-w-[525px]
                        overflow-hidden
                        rounded-lg
                        bg-white
                        py-16
                        px-10
                        text-center
                        sm:px-12
                        md:px-[60px]
                    "
                    >
                    <div className="mb-10 text-center md:mb-16">Luaterra Pedidos</div>
                    {error && <div className="bg-red-700 m-2 p-2 rounded text-white">
                            {error}
                        </div>}

                    <form onSubmit={handleLogin}>
                        <div className="mb-4">
                            <input
                                type="email"
                                value={ email }
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                className="
                                bordder-[#E9EDF4]
                                w-full
                                rounded-md
                                border
                                bg-[#FCFDFE]
                                py-3
                                px-5
                                text-base text-body-color
                                placeholder-[#ACB6BE]
                                outline-none
                                focus:border-primary
                                focus-visible:shadow-none
                                "
                            />
                            { errors.email &&
                            <div className="flex">
                                <span className="text-red-400 text-sm m-2 p-2">{errors.email[0]}</span>
                            </div>}
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                value={ password }
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                className="
                                bordder-[#E9EDF4]
                                w-full
                                rounded-md
                                border
                                bg-[#FCFDFE]
                                py-3
                                px-5
                                text-base text-body-color
                                placeholder-[#ACB6BE]
                                outline-none
                                focus:border-primary
                                focus-visible:shadow-none
                                "
                            />
                            { errors.password &&
                            <div className="flex">
                                <span className="text-red-400 text-sm m-2 p-2">{errors.password[0]}</span>
                            </div>}
                        </div>
                        <div className="mb-10">
                            <button
                                type="submit"
                                className="
                                w-full
                                px-4
                                py-3
                                bg-indigo-500
                                hover:bg-indigo-700
                                rounded-md
                                text-white
                                "
                            >
                                Login
                            </button>
                        </div>
                    </form>
                    <Link
                        to="/forgot-password"
                        className="
                        mb-2
                        inline-block
                        text-base text-[#adadad]
                        hover:text-primary hover:underline
                        "
                    >
                        Forgot Password?
                    </Link>
                    <p className="text-base text-[#adadad]">
                        Not a member yet?
                        <Link to="/register" className="text-primary hover:underline">
                        Sign Up
                        </Link>
                    </p>
                    </div>
                </div>
            </div>
        </div>
        </section>
}

export default Login
