import classNames from "classnames";


export const ThOrderBy: React.FC<{
    toggleOrderBy: (s: string) => void,
    orderByClassnames: (s: string) => any,
    field: string,
    children: React.ReactNode
}> = ({ toggleOrderBy, orderByClassnames, field, children }) => {

    return <th onClick={() => toggleOrderBy(field)} className={classNames(
        'order-pointer',orderByClassnames(field)
    )}>{ children }</th>
};