import { OrderLine as OrderLineModel } from '../@types/api-models';
import { formatDateUtc } from '../utils/datetime';
import TabularData from './TabularData';

interface OrderLineComponentProps {
    lineInfo: OrderLineModel,
    onBack: () => void
}

const OrderLine: React.FC<OrderLineComponentProps> = ({ lineInfo, onBack }) => {
    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Línea pedido</h1>
            <div className="flex items-center justify-between my-5">
                <div></div>
                <button className="btn btn-info btn-xs"
                    onClick={onBack}
                >Go back</button>
            </div>
            <TabularData info={[
                { required: true, label: "id", value: lineInfo.id.toString(), },
                { required: true, label: "lineOrder", value: lineInfo.lineOrder.toString(), },
                { required: true, label: "code", value: lineInfo.code, },
                { required: true, label: "description", value: lineInfo.description, },
                { required: true, label: "replacementCode", value: lineInfo.replacementCode, },
                { required: true, label: "locationCode", value: lineInfo.locationCode, },
                { required: true, label: "quantity", value: lineInfo.quantity.toString(), },
                { required: true, label: "remainQuantity", value: lineInfo.remainQuantity?.toString(), },
                { required: true, label: "bonusQuantity", value: lineInfo.bonusQuantity?.toString(), },
                { required: true, label: "remainBonusQuantity", value: lineInfo.remainBonusQuantity?.toString(), },
                { required: true, label: "price", value: lineInfo.price.toString(), },
                { required: true, label: "percentageDiscount", value: lineInfo.percentageDiscount?.toString(), },
                { required: true, label: "percentageAmount", value: lineInfo.percentageAmount?.toString(), },
                { required: true, label: "chargePercentage", value: lineInfo.chargePercentage?.toString(), },
                { required: true, label: "chargeAmount", value: lineInfo.chargeAmount?.toString(), },
                { required: true, label: "narcoticCoupon", value: lineInfo.narcoticCoupon, },
                { required: true, label: "serviceStoreCode", value: lineInfo.serviceStoreCode, },
                { required: true, label: "serviceDelay", value: lineInfo.serviceDelay, },
                { required: true, label: "serviceStatus", value: lineInfo.serviceStatus, },
                { required: true, label: "serviceDeadline", value: lineInfo.serviceDeadline, },
                { required: true, label: "serviceDefferredDate", value: formatDateUtc(lineInfo.serviceDefferredDate), },
                { required: true, label: "serviceDefferredQty", value: lineInfo.serviceDefferredQty?.toString(), },
                { required: true, label: "remarks", value: lineInfo.remarks, },
                { required: true, label: "condCode", value: lineInfo.condCode, },
                { required: true, label: "condDateIni", value: formatDateUtc(lineInfo.condDateIni), },
                { required: true, label: "condDateEnd", value: formatDateUtc(lineInfo.condDateEnd), },
            ]} />
        </div >

    )
}

export default OrderLine
