import { createContext, useContext, useEffect, useState } from "react";
import axios from "../api/axios";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";
import React from 'react';
import { AuthContextType, ILoginErrors, IRegisterErrors } from "../@types/auth-context";

const AuthContext = createContext<AuthContextType|null>(null);

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({ children}) => {
    const [user, setUser] = useState<any|undefined|null>();
    const [errors, setErrors] = useState<IRegisterErrors & ILoginErrors>({});
    const [error, setError] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const navigate = useNavigate();

    const csrf = () => axios.get('/sanctum/csrf-cookie');

    const resetStatus = () => {
        setErrors({});
        setError(undefined);
        setStatus(undefined);
    }

    const getUser = async () => {
        try {
            const { data, status } = await axios.get('/api/user', {validateStatus: null});
            if (status === 200) {
                setUser(data.data);
            } else {
                setUser(null);
            }
        } catch (ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 401) {
                    setUser(null);
                    //navigate("/login");
                }
            }
        }
    }

    const login = async ({ ...data }) => {
        const fingerprint = await getFingerprint();
        await csrf();
        setErrors({});
        setError(undefined);
        setStatus(undefined);
        try {
            const response = await axios.post('/api/login', {...data, device: fingerprint});
            setStatus(response.data.message);
            getUser();
            navigate("/");
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                } else if (ex.response.status === 401) {
                    setErrors(ex.response.data.errors);
                } else if (ex.response.status === 419) {
                    setErrors({email: [ex.response.data.message]})
                }
            }
        }
    }

    const register = async ({ ...data})=> {
        const fingerprint = await getFingerprint();
        await csrf();
        setErrors({});
        setError(undefined);
        setStatus(undefined)
        try {
            const response = await axios.post('/api/register', {...data, device: fingerprint});
            setStatus(response.data.message);
            getUser();
            // navigate("/");
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                    setError(ex.response.data.message);
                } else if (ex.response.status === 419) {
                    setErrors({name: [ex.response.data.message]})
                    setError(ex.response.data.message);
                }
            }
        }
    };

    const logout = () => {
        axios.post("/api/logout").then(() => {
            setUser(null);
        });
    };

    useEffect(() => {
        // I'll run when the component is mounted
        // Setting the initial state for this component
        if (!user) {
          getUser();
        }
        return () => {
            // all the clean-ups related to this component
            // I'll run when the component is unmounted
        }
    }, [])

    return <AuthContext.Provider value={{ user, errors, error, status, getUser, login, register, logout, csrf, resetStatus }}>
        { children }
    </AuthContext.Provider>
} 

export default function useAuthContext() {
    return useContext(AuthContext) as AuthContextType;
}