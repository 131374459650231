import React, { useState } from 'react'
import { Report } from '../@types/api-models'
import ConfirmModal from './ConfirmModal';
import axios from '../api/axios';
import { formatDateUtc } from '../utils/datetime';


interface ReportListProps {
    reports: Report[],
    refetch?: () => void,
}

const ReportList: React.FC<ReportListProps> = ({ reports, refetch }) => {
    
    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Tipo</th>
                        <th>Creado en</th>
                        <th>Estado</th>
                        <th>Fecha estado</th>
                        <th>error</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map(report => (
                        <tr key={report.id.toString()}>
                            <th>{report.id.toString()}</th>
                            <td>{report.reportType}</td>
                            <td>{formatDateUtc(report.createdAt)}</td>
                            <td>{report.status}</td>
                            <td>{formatDateUtc(report.statusAt)}</td>
                            <td>{report.error}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ReportList
