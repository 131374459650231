import React from 'react'

interface InfoModalProps {
  id: string,
  title?: string,
  onOk?: () => void,
  children?: React.ReactNode,
}

const InfoModal: React.FC<InfoModalProps> = ({id, title="", onOk=()=>{}, children}) => {
  return (
    <dialog id={id} className="modal" open={true}>
        <div className="modal-box">
            <h3 className="font-bold text-lg">{title}!</h3>
            {children}
            <div className="modal-action">
              <button onClick={onOk} className="btn">Aceptar</button>
            </div>
        </div>
    </dialog>
  )
}

export default InfoModal
