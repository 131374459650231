import React, { useState, useEffect } from 'react'
import useAuthContext from '../context/AuthContext';
import axios from '../api/axios';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {isAxiosError} from 'axios';
import { IResetPasswordErrors } from '../@types/auth-context';

const ResetPassword: React.FC = () => {
    const [email, setEmail] = useState<String|null>(null);
    const [errors, setErrors] = useState<IResetPasswordErrors>({});
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null)
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [searchParams] = useSearchParams();
    const { token } = useParams();

    const { csrf } = useAuthContext();

    useEffect(() => {
        // I'll run when the component is mounted
        // Setting the initial state for this component
        setEmail(searchParams.get('email'));
        // console.log(email);
        return () => {
            // all the clean-ups related to this component
            // I'll run when the component is unmounted
              setEmail("");
              setPassword("");
              setPasswordConfirmation("");
              setErrors({});
              setError(null);
              setStatus(null);
              
          }
    }, []); // for the first time



    const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        await csrf();
        setErrors({});
        setError(null);
        setStatus(null);
        try {
            const response = await axios.post('/api/reset-password', {
                email,
                token,
                password,
                password_confirmation
            });
            setStatus(response.data.message);
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                    setError(ex.response.data.message);
                }
            }
        }
    };


    return <section className="bg-[#F4F7FF] py-20 lg:py-[120px]">
        <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                <div
                    className="
                        relative
                        mx-auto
                        max-w-[525px]
                        overflow-hidden
                        rounded-lg
                        bg-white
                        py-16
                        px-10
                        text-center
                        sm:px-12
                        md:px-[60px]
                    "
                    >
                        {status && <div className="bg-green-700 m-2 p-2 rounded text-white">
                            {status}
                            <div className='m-2 p-2'>
                                Goto <Link to="/login">Login</Link>
                            </div>
                        </div>}

                        {error && <div className="bg-red-700 m-2 p-2 rounded text-white">
                            {error}
                        </div>}

                        <div className="mb-10 text-center md:mb-16">Add yout new password</div>
                        <form onSubmit={handleSubmit}
                        >
                            <div className="mb-6">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={ password }
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="
                                    bordder-[#E9EDF4]
                                    w-full
                                    rounded-md
                                    border
                                    bg-[#FCFDFE]
                                    py-3
                                    px-5
                                    text-base text-body-color
                                    placeholder-[#ACB6BE]
                                    outline-none
                                    focus:border-primary
                                    focus-visible:shadow-none
                                    "
                                />
                                { errors.password &&
                                <div className="flex">
                                    <span className="text-red-400 text-sm m-2 p-2">{errors.password[0]}</span>
                                </div>}
                            </div>
                            <div className="mb-6">
                                <input
                                    type="password"
                                    placeholder="Password Confirmation"
                                    value={ password_confirmation }
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    className="
                                    bordder-[#E9EDF4]
                                    w-full
                                    rounded-md
                                    border
                                    bg-[#FCFDFE]
                                    py-3
                                    px-5
                                    text-base text-body-color
                                    placeholder-[#ACB6BE]
                                    outline-none
                                    focus:border-primary
                                    focus-visible:shadow-none
                                    "
                                />
                                { errors.password_confirmation &&
                                <div className="flex">
                                    <span className="text-red-400 text-sm m-2 p-2">{errors.password_confirmation[0]}</span>
                                </div>}
                            </div>
                            <button
                                type="submit"
                                className="
                                w-full
                                px-4
                                py-3
                                bg-indigo-500
                                hover:bg-indigo-700
                                rounded-md
                                text-white
                                "
                            >
                                Reset Password
                            </button>
                        </form>
                    
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default ResetPassword
