import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import React, { useState } from 'react'
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { UsersResponse } from '../@types/api-models';
import UserList from '../components/UserList';


const Users: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['users', currentPage],
        queryFn: async () => {
            const response = await axios.get<UsersResponse>(`/api/v1/users?_page=${currentPage}&_limit=${ITEM_PER_PAGE}`);
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const users = response.data.data;
            return { users, total, perPage }
        },
        placeholderData: keepPreviousData,
    });

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Usuarios</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <UserList users={data? data.users: []} />
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default Users