import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import React, { useState } from 'react'
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { ServiceStatusesResponse } from '../@types/api-models';
import InvitationList from '../components/InvitationList';
import { isAxiosError } from 'axios';
import ServiceStatusList from '../components/ServiceStatusList';


const ServiceStatuses: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['serviceStatuses', currentPage],
        queryFn: async () => {
            const response = await axios.get<ServiceStatusesResponse>(`/api/v1/service-statuses?_page=${currentPage}`);
            const services = response.data.data;
            return { services }
        },
        placeholderData: keepPreviousData,
    });

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Servicios</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <ServiceStatusList services={data? data.services: []} refetch={refetch}/>
                <div className="flex items-center justify-between my-5">
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default ServiceStatuses