import React from 'react'
import { ServiceStatus } from '../@types/api-models'
import axios from '../api/axios';
import { formatDateUtc } from '../utils/datetime';


interface ServiceStatusListProps {
    services: ServiceStatus[],
    refetch?: () => void,
}

const ServiceStatusList: React.FC<ServiceStatusListProps> = ({ services, refetch }) => {

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const onoff = event.target.checked;
        const id = event.target.getAttribute('data-key');
        try {
            await axios.post(`/api/v1/service-statuses/${id}/status`, {status: onoff ? 'enabled':'disabled'});
            
        } catch(ex: any) {
            // do nothing for now
        }

        if (refetch) {
            refetch();
        }
    }

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Service</th>
                        <th>Activo</th>
                        <th>Ultima ejecución</th>
                        <th>Ultimo éxito</th>
                        <th>En ejecución</th>
                        <th>Siguiente</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {services.map(service => (
                        <tr key={service.id.toString()}>
                            <th>{service.id.toString()}</th>
                            <td>{service.description}</td>
                            <td>
                                <input data-key={service.id.toString()} type="checkbox" className="toggle toggle-sm" defaultChecked={service.status === 'enabled'} onChange={handleChange}/>
                            </td>
                            <td>{formatDateUtc(service.lastExecutedAt)}</td>
                            <td>{formatDateUtc(service.lastSuccessAt)}</td>
                            <td>{service.isRunning? 'true' : 'false'}</td>
                            <td>{formatDateUtc(service.nextDueTime)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ServiceStatusList
