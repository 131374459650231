import React from 'react'

interface LoadingSpinnerProps {
  size?: number
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({size = 0}) => {

  return (<>
    {(size === 0) && <span className="loading loading-spinner loading-xs"></span>}
    {(size === 1) && <span className="loading loading-spinner loading-sm"></span>}
    {(size === 2) && <span className="loading loading-spinner loading-md"></span>}
    {(size === 3) && <span className="loading loading-spinner loading-lg"></span>}
    </>
  )
}

export default LoadingSpinner
