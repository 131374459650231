import axios from '../api/axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WooOrder as WooOrderModel, Order, OrderLine, WooOrderLine } from '../@types/api-models';
import LoadingSpinner from '../components/LoadingSpinner';
import ConfirmModal from '../components/ConfirmModal';
import TabularData from '../components/TabularData';
import { OrderLineInfo } from '../@types/shared-models';
import OrderLinesFromWooOrderLine from '../components/OrderLinesFromWooOrderLine';
import { IconCancel, IconClockFill, IconTick } from '../components/icons/icons';
import { useScrollSaver } from '../hooks/ScrollSaver';
import { wooLineStatus } from '../utils/orderutils';
import CreateOrderFromWoo from '../components/CreateOrderFromWoo';
import { formatDateUtc } from '../utils/datetime';

const WooOrder: React.FC = () => {
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [orderInfo, setOrderInfo] = useState<WooOrderModel>();
    const [depsOrdersInfo, setdepsOrdersInfo] = useState<Order[]>();
    const [linesInfo, setLinesInfo] = useState<{ [id: string]: OrderLineInfo[]; }>({});
    const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const [showWooLine, setShowWooLine] = useState<WooOrderLine|null>(null);
    const [showLinesInfo, setShowLinesInfo] = useState<OrderLineInfo[]|null>(null);
    const [showCreateOrderFromWoo, setShowCreateOrderFromWoo] = useState<boolean>(false);
    const [saveScroll, restoreScroll] = useScrollSaver();
    
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    var axiosCalled = false;
    const getOrder = () => {
        if (!axiosCalled) {
            axios.get(`/api/v1/wooorders/${id}`)
                .then(response => {
                    setOrderInfo(response.data.data);
                    return axios.get(`api/v1/wooorders/${id}/orders`)
                    .then(response => {
                        let ordersInfo:Order[] = response.data.data; 
                        setdepsOrdersInfo(ordersInfo);
                        if (ordersInfo) {
                            const dict: { [id: string]: OrderLineInfo[]; } = {};
                            for(var i=0; i < ordersInfo.length; i++) {
                                const order = ordersInfo[i];
                                if (order.lines){
                                    for(var j=0; j < order.lines.length; j++){
                                        const line: OrderLine = order.lines[j];
                                        const key = line.wooOrderLineId.toString();
                                        if (!(key in dict)) {
                                            dict[key] = [];
                                        }
                                        dict[line.wooOrderLineId.toString()].push({order, line});
                                    }
                                }
                            }
                            setLinesInfo(dict);
                        }
                    });
                }).finally(() => setIsFetching(false));
            axiosCalled = true;
        }
    }

    const handleChangeStatusOrder = (status: string) => {
        setIsFetching(true);
        axios.post(`/api/v1/wooorders/${id}/status`, {status})
            .then(response => {setOrderInfo(response.data.data)})
            .finally(() => setIsFetching(false)); 
    }

    useEffect(() => {
        if (location.state) {
            const { orderInfo } = location.state;
            if (orderInfo) {
                setOrderInfo(orderInfo)
            } else {
                getOrder();
            }
        } else {
            getOrder();
        }
    }, [])


    const onShowOrderLines = (line: WooOrderLine, linesInfo: OrderLineInfo[]) => {
        saveScroll();
        setShowWooLine(line);
        setShowLinesInfo(linesInfo);
    }

    const onHideOrderLines = () => {
        setShowWooLine(null);
        setShowLinesInfo(null);
        restoreScroll();
    }

    const onShowCreateOrderFromWoo = () => {
        saveScroll();
        setShowCreateOrderFromWoo(true);
    }

    const onHideCreateOrderFromWoo = () => {
        setShowCreateOrderFromWoo(false);
        restoreScroll()
    }

    return (
        <>
        { showWooLine != null && showLinesInfo != null && 
            <OrderLinesFromWooOrderLine wooLine={showWooLine} linesInfo={showLinesInfo} onBack={onHideOrderLines}/>
        }

        { orderInfo && showCreateOrderFromWoo && 
            <CreateOrderFromWoo wooOrder={orderInfo} linesInfo={linesInfo} onBack={onHideCreateOrderFromWoo}/>
        }

        { (showWooLine == null || showLinesInfo == null) && !showCreateOrderFromWoo && 
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Pedido WooCommerce</h1>
            <div className="flex items-center justify-between my-5">
                {isFetching ? <LoadingSpinner size={0} /> : <div></div>}
                <div className="join join-vertical lg:join-horizontal gap-2">
                    {orderInfo && orderInfo.status === "received" &&
                        <>
                        <button disabled={isFetching} className="btn btn-info btn-xs"
                            onClick={() => setShowAcceptModal(true)}
                        >Aceptar orden</button>
                        {showAcceptModal && <ConfirmModal 
                            id="MyAcceptModal"
                            title="Aceptar pedido"
                            message="¿Está seguro de aceptar este pedido?"
                            onOk={()=>{setShowAcceptModal(false);handleChangeStatusOrder('pending');}}
                            onCancel={()=>setShowAcceptModal(false)}/>}
                        </>
                    }

                    {orderInfo && orderInfo.status === "received" &&
                        <>
                        <button disabled={isFetching} className="btn btn-info btn-xs"
                            onClick={() => setShowRejectModal(true)}
                        >Rechazar orden</button>
                        {showRejectModal && <ConfirmModal 
                            id="MyRejectModal"
                            title="Rechazar pedido"
                            message="¿Está seguro de rechazar este pedido?"
                            onOk={()=>{setShowRejectModal(false);handleChangeStatusOrder('rejected');}}
                            onCancel={()=>setShowRejectModal(false)}/>}
                        </>
                    }

                    {orderInfo &&
                        <>
                        <button disabled={isFetching} className="btn btn-info btn-xs"
                            onClick={onShowCreateOrderFromWoo}
                        >Pedir faltas</button>
                        </>
                    }

                    {location.state ?
                        <button disabled={isFetching} className="btn btn-info btn-xs"
                            onClick={() => navigate('/wooorders', { state: location.state })}
                        >Go back list</button>


                        : <div></div>
                    }
                </div>
            </div>


            {orderInfo ?
                <>
                    <TabularData info={[
                        {required: true, label: 'Id', value: orderInfo.id.toString()},
                        {required: true, label: 'Estado', value: orderInfo.status},
                        {required: true, label: 'Descargado', value: formatDateUtc(orderInfo.dateDownloaded)},
                        {type: 'textarea', label: 'Error', value: orderInfo.error},
                    ]} />
                    <h2>Productos:</h2>
                    <table className="table table-xs">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Id orig</th>
                                <th>Nombre</th>
                                <th>Qty</th>
                                <th>Precio</th>
                                <th>Total</th>
                                <th>sku</th>
                                <th>provider</th>
                                <th>sku orig</th>
                                <th>Avisos</th>
                                <th>Pendiente</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderInfo.lines ? orderInfo.lines.map(line => (
                                <tr key={line.id.toString()}>
                                    <th>{line.id.toString()}</th>
                                    <td>{line.lineId}</td>
                                    <td>{line.name}</td>
                                    <td>{line.quantity}</td>
                                    <td>{line.price}</td>
                                    <td>{line.total}</td>
                                    <td>{line.sku}</td>
                                    <td>{line.providerName}</td>
                                    <td>{line.origSku}</td>
                                    <td>{line.warning}</td>
                                    <td>
                                        <div className='flex items-center'>
                                        {(() => {
                                            const lineStatus = wooLineStatus(orderInfo, line, linesInfo[line.id.toString()]?? [])
                                            switch(lineStatus) {
                                                case 'pending':
                                                case 'orig-pending':
                                                    return <IconClockFill fill="#fcc419"/>
                                                case 'ok':
                                                    return <IconTick fill="#20C997"/>;
                                                case 'fail':
                                                case 'orig-fail':
                                                    return <IconCancel fill="red"/>;
                                                case 'over-ok':
                                                    return <IconTick fill="red"/>;
                                            }
                                        })()}
                                        { (line.id.toString() in linesInfo) && <button
                                                onClick={() => onShowOrderLines(line, linesInfo[line.id.toString()])}
                                                className="btn btn-ghost btn-xs">líneas
                                            </button>}
                                        </div>
                                    </td>
                                </tr>
                            )): null}
                        </tbody>
                    </table>
                </>
                : <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            }
        </div>
        }
        </>
    )
}

export default WooOrder
