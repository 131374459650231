import React from 'react'

interface CodeModalProps {
  id: string,
  title?: string,
  code?: string,
  onOk?: () => void,
}

const CodeModal: React.FC<CodeModalProps> = ({id, title="", code="", onOk=()=>{}}) => {

  const prettyCode = () => {
    try {
      return JSON.stringify(JSON.parse(code), null, 2);
    } catch (ex: any) {
      console.log(ex);
      return code;
    }
  };

  return (
    <dialog id={id} className="modal" open={true}>
        <div className="modal-box">
            <h3 className="font-bold text-lg">{title}!</h3>
            <div className="mockup-code">
              <pre><code className="text-xs">
                {prettyCode()}
              </code></pre>
            </div>
            <div className="modal-action">
              <button onClick={onOk} className="btn">Aceptar</button>
            </div>
        </div>
    </dialog>
  )
}

export default CodeModal
