import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import AuthLayout from './layouts/AuthLayout';
import GuestLayout from './layouts/GuestLayout';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import WooOrders from './pages/WooOrders';
import WooOrder from './pages/WooOrder';
import Orders from './pages/Orders';
import Order from './pages/Order';
import OrderLine from './pages/OrderLine';
import StoreFtp from './pages/StoreFtps';
import StoreInternals from './pages/StoreInternals';
import StoreProducts from './pages/StoreProducts';
import Invitations from './pages/Invitations';
import ServiceStatuses from './pages/ServiceStatuses';
import Users from './pages/Users';
import Reports from './pages/Reports';
//import logo from './logo.svg';
//import './App.css';

function App() {
  return (
    // <div className="bg-slate-100 max-w-6xl mx-auto">
    <div className="bg-slate-100 min-h-screen">

      {/* <div className="max-w-7xl mx-auto mt-6"> */}
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={ <Home/> } />
            {/* <Route path="/products" element={ <Products /> } /> */}
            <Route path="/wooorders" element={ <WooOrders /> } />
            <Route path="/wooorders/:id" element={ <WooOrder /> } />
            <Route path="/orders" element={ <Orders /> } />
            <Route path="/orders/:id" element={ <Order /> } />
            <Route path="/orders/:id/line/:line" element={ <OrderLine /> } />
            <Route path="/store-ftps" element={ <StoreFtp />} />
            <Route path="/store-internals" element={ <StoreInternals />} />
            <Route path="/store-products" element={ <StoreProducts />} />
            <Route path="/invitations" element={ <Invitations />} />
            <Route path="/reports" element={ <Reports />} />
            <Route path="/service-statuses" element={ <ServiceStatuses />} />
            <Route path="/users" element={ <Users />} />
          </Route>
          <Route element={<GuestLayout />}>
            <Route path="/login" element={ <Login/> } />
            <Route path="/register" element={ <Register/> } />
            <Route path="/forgot-password" element={ <ForgotPassword /> } />
            <Route path="/reset-password/:token" element={ <ResetPassword /> } />
          </Route>
        </Routes>
      {/* </div> */}
    </div>
    // <div className="App">
      
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
