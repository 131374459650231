import axios from '../api/axios';
import React, { useEffect, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrderLine, OrderLineIssue, Order } from '../@types/api-models';
import LoadingSpinner from './LoadingSpinner';
import ConfirmModal from './ConfirmModal';
import CodeModal from './CodeModal';
import TabularData from './TabularData';
import OrderLineComponent from './OrderLineComponent';
import { formatDateUtc } from '../utils/datetime';

interface OrderComponentProps {
    orderInfo: Order|null,
    isFetching: boolean,
    handleChangeStatusOrder?: (status: string) => void,
    onBack?: () => void,
}

const OrderComponent: React.FC<OrderComponentProps> = ({orderInfo, isFetching, handleChangeStatusOrder, onBack}) => {
    const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [showJsonOrderModal, setShowJsonOrderModal] = useState<boolean>(false);
    const [showJsonSentModal, setShowJsonSentModal] = useState<boolean>(false);
    const [showOrderLine, setShowOrderLine] = useState<OrderLine|null>(null);

    const [orderLineIssues, setOrderLineIssues] = useState<OrderLineIssue[]|undefined>();

    const [offset, setOffset] = useState(0);
    const [saveOffset, setSafeOffset] = useState(0);

    const onScroll = () => setOffset(window.scrollY);

    const onBackOrderLine = () => {
        setShowOrderLine(null);
        setTimeout(() => {
            window.scrollTo(0, saveOffset);
        }, (10));
    }

    const onShowOrderLine = (line: OrderLine) => {
        setSafeOffset(offset);
        setShowOrderLine(line);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, (10));
    }

    useEffect(() => {
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        // clean up code
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    return (
        <>
        {showOrderLine != null && <OrderLineComponent lineInfo={showOrderLine} onBack={onBackOrderLine}/>}
        {showOrderLine == null &&
            <div className="max-w-4xl mx-auto">
                <h1 className="text-center text-2xl my-5 underline font-bold">Pedido</h1>
                <div className="flex items-center justify-between my-5">
                    {isFetching ? <LoadingSpinner size={0} /> : <div></div>}
                    <div className="join join-vertical lg:join-horizontal gap-2">
                        {orderInfo && orderInfo.jsonOrder &&
                            <>
                                <button disabled={isFetching} className="btn btn-info btn-xs"
                                    onClick={() => setShowJsonOrderModal(true)}
                                >Order</button>
                                {showJsonOrderModal && <CodeModal
                                    id="JsonOrderModal"
                                    title="Pedido recibido"
                                    code={JSON.stringify(orderInfo.jsonOrder, null, 2)}
                                    onOk={() => { setShowJsonOrderModal(false) }} />}
                            </>
                        }

                        {orderInfo && orderInfo.jsonSent1 &&
                            <>
                                <button disabled={isFetching} className="btn btn-info btn-xs"
                                    onClick={() => setShowJsonSentModal(true)}
                                >Petición</button>
                                {showJsonSentModal && <CodeModal
                                    id="JsonSentModal"
                                    title="Pedido enviado"
                                    code={JSON.stringify(orderInfo.jsonSent1, null, 2)}
                                    onOk={() => { setShowJsonSentModal(false) }} />}
                            </>
                        }

                        {orderInfo && handleChangeStatusOrder && orderInfo.status === "created" &&
                            <>
                                <button disabled={isFetching} className="btn btn-info btn-xs"
                                    onClick={() => setShowAcceptModal(true)}
                                >Enviar orden</button>
                                {showAcceptModal && <ConfirmModal
                                    id="MySendModal"
                                    title="Enviar pedido"
                                    message="¿Está seguro de enviar este pedido?"
                                    onOk={() => { setShowAcceptModal(false); handleChangeStatusOrder('pending'); }}
                                    onCancel={() => setShowAcceptModal(false)} />}
                            </>
                        }

                        {orderInfo && handleChangeStatusOrder && orderInfo.status === "created" &&
                            <>
                                <button disabled={isFetching} className="btn btn-info btn-xs"
                                    onClick={() => setShowRejectModal(true)}
                                >Rechazar orden</button>
                                {showRejectModal && <ConfirmModal
                                    id="MyRejectModal"
                                    title="Rechazar pedido"
                                    message="¿Está seguro de rechazar este pedido?"
                                    onOk={() => { setShowRejectModal(false); handleChangeStatusOrder('rejected'); }}
                                    onCancel={() => setShowRejectModal(false)} />}
                            </>
                        }

                        {onBack ?
                            <button disabled={isFetching} className="btn btn-info btn-xs"
                                onClick={onBack}
                            >Go back</button>
                            : <div></div>
                        }
                    </div>
                </div>

                { orderLineIssues && <CodeModal
                    id="OrderLineIssues"
                    title="Notificationes"
                    code={JSON.stringify(orderLineIssues, null, 2)}
                    onOk={() => { setOrderLineIssues(undefined) }} />
                }

                {orderInfo ?
                    <>
                        <TabularData info={[
                            { required: true, label: "Id", value: orderInfo.id.toString(), },
                            { required: true, label: "Status", value: orderInfo.status, },
                            { required: true, label: "Creado", value: formatDateUtc(orderInfo.createdAt), },
                            { required: true, label: "Actualizado", value: formatDateUtc(orderInfo.updatedAt), },
                            { required: true, label: "orderNumber", value: orderInfo.orderNumber, },
                            { required: true, label: "clientCode", value: orderInfo.clientCode, },
                            { required: true, label: "sendAddress", value: orderInfo.sendAddress, },
                            { required: true, label: "storeCode", value: orderInfo.storeCode, },
                            { required: true, label: "origOrderNumber", value: orderInfo.origOrderNumber, },
                            { required: true, label: "orderType", value: orderInfo.orderType, },
                            { required: true, label: "orderDate", value: formatDateUtc(orderInfo.orderDate), },
                            { required: true, label: "serviceDate", value: formatDateUtc(orderInfo.serviceDate), },
                            { label: "Pedido enviado", value: formatDateUtc(orderInfo.jsonOrderAt), },
                            { label: "Primer envío", value: formatDateUtc(orderInfo.jsonSent1At), },
                            { label: "Error", value: orderInfo.error, type: "textarea", },
                        ]} />

                        <h2>Productos:</h2>
                        <table className="table table-xs">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Orden</th>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Código reempl.</th>
                                    <th>Cantidad</th>
                                    <th>Cantidad pend.</th>
                                    <th>Precio</th>
                                    <th>Descuento</th>
                                    <th>Estado servicio</th>
                                    <th>Remarks</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderInfo.lines ? orderInfo.lines.map(line => (
                                    <tr key={line.id.toString()}>
                                        <th>{line.id.toString()}</th>
                                        <td>{line.lineOrder}</td>
                                        <td>{line.code}</td>
                                        <td>{line.description}</td>
                                        <td>{line.replacementCode}</td>
                                        <td>{line.quantity}</td>
                                        <td>{line.remainQuantity}</td>
                                        <td>{line.price}</td>
                                        <td>{line.percentageDiscount}</td>
                                        <td>{line.serviceStatus}</td>
                                        <td>{line.remarks}</td>
                                        <td>
                                            <div className="join">
                                                <button
                                                    onClick={() => onShowOrderLine(line)}
                                                    className="btn btn-ghost btn-xs">detalles
                                                </button>
                                                {line.issues != null && line.issues.length > 0 &&
                                                <button onClick={() => setOrderLineIssues(line.issues)} className="btn btn-ghost btn-xs">
                                                    notif
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </>
                    : <div className="flex flex-col gap-4">
                        <div className="skeleton h-4 w-full"></div>
                        <div className="skeleton h-4 w-full"></div>
                        <div className="skeleton h-32 w-full"></div>
                    </div>
                }
            </div>
        }
        </>
    )
}

export default OrderComponent
