import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {Order as OrderModel } from '../@types/api-models';
import OrderComponent from '../components/OrderComponent';
import { getOrder as getOrderApi, changeOrderStatus } from '../services.tsx/api';

const Order: React.FC = () => {
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [orderInfo, setOrderInfo] = useState<OrderModel|null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    var axiosCalled = false;
    const getOrder = () => {
        if (!axiosCalled) {
            getOrderApi(id?? '0')
                .then(response => { setOrderInfo(response.data) })
                .finally(() => setIsFetching(false));
            axiosCalled = true;
        }
    }

    const handleChangeStatusOrder = (status: string) => {
        setIsFetching(true);
        changeOrderStatus(id?? '0', status)
            .then(response => { setOrderInfo(response.data) })
            .finally(() => setIsFetching(false));
    }

    useEffect(() => {

        if (location.state) {
            const { orderInfo, scrollPosition, ordersState } = location.state;
            if (orderInfo) {
                setOrderInfo(orderInfo);
                axiosCalled = true;
                setIsFetching(false)
            } else if (ordersState && ordersState.orderInfo) {
                setOrderInfo(ordersState.orderInfo);
                axiosCalled = true;
                setIsFetching(false)
            } else {
                getOrder();
            }

            if (ordersState) {
                setTimeout(() => {
                    window.scrollTo(0, scrollPosition);
                }, (10));
            }
        } else {
            getOrder();
        }

    }, [])

    return (
        <>
        <OrderComponent 
            orderInfo={orderInfo}
            isFetching={isFetching}
            handleChangeStatusOrder={handleChangeStatusOrder}
            onBack={() => navigate('/orders', { state: location.state.ordersState ? location.state.ordersState : location.state })}
            />
        </>
    )
}

export default Order
