import { CreateOrderItem, CreateOrdersRequest } from "../@types/api-models";
import axios from "../api/axios"
import { ITEM_PER_PAGE } from "../utils/constants";

export const getProductList = async (page: number) => {
    const response = await axios.get(`https://jsonplaceholder.typicode.com/posts?_page=${page}&_limit=${ITEM_PER_PAGE}`);
    const totalData = response.headers["x-total-count"];
    const data = response.data;
    return {
        products: data,
        totalData
    }
}

export const getStores = async () => {
    const response = await axios.get('/api/v1/stores');
    const data = response.data;
    return data.data;
}

export const getOrder = async (id: string) => {
    const response = await axios.get(`/api/v1/orders/${id}`);
    return response.data;
}

export const changeOrderStatus = async (id: string, status: string) => {
    const response = await axios.post(`/api/v1/orders/${id}/status`, { status });
    return response.data;
}

export const getProductsBySkus = async (skus: string[], page: number, limit:number = 20) => {
    const response = await axios.get(`api/v1/store-products`, {
        params: {
            code: { in: skus},
            _page: page,
            _limit: limit,
        },
        paramsSerializer: {
            indexes: null, // use brackets without indexes
        }
    });
    
    return response.data;
}

export const createOrdersApi = async (request: CreateOrdersRequest) => {
    const response = await axios.post('api/v1/orders-multiple', request);

    return response.data;
}