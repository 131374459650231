import React from 'react'
import { Order } from '../@types/api-models'
import { generatePath, useNavigate } from 'react-router-dom'
import { StateOrderList } from '../@types/shared-models';
import { ThOrderBy } from './ThOrderBy';
import { formatDateUtc } from '../utils/datetime';


interface OrderProps {
    orders: Order[],
    prevState: StateOrderList,
    toggleOrderBy: (field: string) => void,
    orderByClassnames: (field: string) => any,
}

const OrderList: React.FC<OrderProps> = ({ orders, prevState, toggleOrderBy, orderByClassnames }) => {
    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='id'>#</ThOrderBy>
                        <th>Numero Pedido</th>
                        <th>Estado</th>
                        <th>Codigo cliente</th>
                        <th>Numero Luaterra</th>
                        <th>Tipo Orden</th>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='orderDate'>Fecha Orden</ThOrderBy>
                        <th>Fecha servicio</th>
                        <th>Almacén</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id.toString()}>
                            <th>{order.id.toString()}</th>
                            <td>{order.orderNumber}</td>
                            <td>{order.status}</td>
                            <td>{order.clientCode}</td>
                            <td>{order.origOrderNumber}</td>
                            <td>{order.orderType}</td>
                            <td>{formatDateUtc(order.orderDate)}</td>
                            <td>{formatDateUtc(order.serviceDate)}</td>
                            <td>{order.storeName}</td>
                            <td>
                                <button
                                    onClick={() => navigate(generatePath('/orders/:id', { id: order.id.toString() }), { state: prevState })}
                                    className="btn btn-ghost btn-xs">detalles
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OrderList
