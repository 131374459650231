import React, { useState } from 'react'
import { Invitation } from '../@types/api-models'
import ConfirmModal from './ConfirmModal';
import axios from '../api/axios';
import { formatDateUtc } from '../utils/datetime';


interface InvitationListProps {
    invitations: Invitation[],
    refetch?: () => void,
}

const InvitationList: React.FC<InvitationListProps> = ({ invitations, refetch }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState<Number|undefined>();
    
    const handleDeleteModal = async () => {
        setShowDeleteModal(false);
        try {
            await axios.delete(`/api/v1/invitations/${idToDelete}`);
            if (refetch) {
                refetch();
            }
        } catch(ex: any) {
            // do nothing for now
        }

        setIdToDelete(undefined);
    }

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Email</th>
                        <th>Token invitación</th>
                        <th>Registrado</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invitations.map(invitation => (
                        <tr key={invitation.id.toString()}>
                            <th>{invitation.id.toString()}</th>
                            <td>{invitation.email}</td>
                            <td>{invitation.invitationToken}</td>
                            <td>{formatDateUtc(invitation.registeredAt)}</td>
                            <td>
                                <button
                                    onClick={() => {setShowDeleteModal(true);setIdToDelete(invitation.id)}}
                                    className="btn btn-ghost btn-xs">borrar
                                </button>
                                {showDeleteModal && <ConfirmModal 
                                    id="MyDeleteModal"
                                    title="Borrar invitación"
                                    message="¿Está seguro de borrar esta invitación?"
                                    onOk={handleDeleteModal}
                                    onCancel={()=>{setShowDeleteModal(false); setIdToDelete(undefined);}}/>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default InvitationList
