import React from 'react'
import { StoreFtp } from '../@types/api-models'
import { formatDateUtc } from '../utils/datetime'


interface StoreFtpListProps {
    storeFtps: StoreFtp[],
}

const StoreFtpList: React.FC<StoreFtpListProps> = ({ storeFtps }) => {

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Almacén</th>
                        <th>Estado</th>
                        <th>Ultima ejec.</th>
                        <th>Ultima ejec. OK</th>
                        <th>Servidor</th>
                        <th>Puerto</th>
                        <th>Usuario</th>
                        <th>Fichero</th>
                        <th>Parser</th>
                    </tr>
                </thead>
                <tbody>
                    {storeFtps.map(storeFtp => (
                        <tr key={storeFtp.id.toString()}>
                            <th>{storeFtp.id.toString()}</th>
                            <td>{storeFtp.storeName}</td>
                            <td>{storeFtp.status}</td>
                            <td>{formatDateUtc(storeFtp.executedAt)}</td>
                            <td>{formatDateUtc(storeFtp.lastSuccessExecutionAt)}</td>
                            <td>{storeFtp.server}</td>
                            <td>{storeFtp.port.toString()}</td>
                            <td>{storeFtp.username}</td>
                            <td>{storeFtp.file}</td>
                            <td>{storeFtp.parseType}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StoreFtpList
