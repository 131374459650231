import { useEffect, useState } from "react";


export const useScrollSaver = (initialValue?: number): [
    () => void,
    () => void,
    (v: number) => void,
    number,
] => {
    const [scroll, setScroll] = useState<number>(initialValue || 0);
    const [saved, setSaved] = useState<number|null>(null);

    const saveScroll = () => {
        setSaved(scroll);
    }

    const restoreScroll = () => {
        let s = saved || 0;
        setSaved(null);
        setTimeout(() => {
            window.scrollTo(0, s);
        }, (10));
    }

    const onScroll = () => setScroll(window.scrollY);
    useEffect(() => {
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        // clean up code
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    return [saveScroll, restoreScroll, setScroll, scroll]
}