import { WooOrder, WooOrderLine } from "../@types/api-models";
import { OrderLineInfo } from "../@types/shared-models";

export interface StoreAndAlt {
    store: string,
    alt?: string,
}

function getWooRemainQuantity(
    wooLine: WooOrderLine,
    linesInfo?: OrderLineInfo[]
){
    let remain = wooLine.quantity;
    if (linesInfo) {
        linesInfo.forEach(line => {
            let remainQuantity = line.line.remainQuantity?? 0;
            remain = remain - (line.line.quantity - remainQuantity);
        });
    }
    return remain;
}

function getStoreNamesFromLines(linesInfo?: OrderLineInfo[]) {
    const names: StoreAndAlt[] = [];
    if (linesInfo) {
        linesInfo.forEach(line => {
            if (line.order.storeName) {
                const store: StoreAndAlt = {
                    store: line.order.storeName
                };
                
                if (line.order.storeCode) {
                    store.alt = line.order.storeCode;
                }

                names.push(store);
            }
        })
    }

    return names;
}

function wooLineStatus(
    wooOrder: WooOrder,
    wooLine: WooOrderLine,
    linesInfo?: OrderLineInfo[]
) {

    // if there are fedicom lines
    if (linesInfo && linesInfo.length > 0) {
        let quantity = 0;
        let pendingOrders = 0;
        let errorOrders = 0;
        linesInfo.forEach(x => {
            if (['created', 'pending'].includes(x.order.status)) {
                pendingOrders++;
            } 

            if (['error'].includes(x.order.status)) {
                errorOrders++;
            } 
            
            const remainQuantity = x.line.remainQuantity?? 0;
            quantity += (x.line.quantity - remainQuantity);
        });
        // if still pendinf orders -> PENDING
        if (pendingOrders > 0) {
            return 'pending';
        }

        if (errorOrders > 0) {
            return 'fail';  
        }

        // quantity fulfilled -> OK
        if (wooLine.quantity === quantity) {
            return 'ok';
        }
        // quantity > expected -> OVEROK
        if (wooLine.quantity === quantity) {
            return 'over-ok';
        }
        // else -> FAIL
        return 'fail';        
    } else {
        // no fedicom lines
        if (['created', 'pending'].includes(wooOrder.status)) {
            // orig order not procesed yet -> PENDING
            return 'orig-pending';
        } else {
            // else -> FAIL
            return 'orig-fail';
        }
    }
}

export {
    wooLineStatus,
    getWooRemainQuantity,
    getStoreNamesFromLines,
}