import { useState } from "react";
import { OrderBy } from "../@types/shared-models";


export const useOrderBy = (initialValue: OrderBy|null): [
    OrderBy|null,
    (field: string) => void,
    (field: string) => any,
    (v: OrderBy|null) => void,
] => {
    const [orderBy, setOrderBy] = useState<OrderBy|null>(initialValue);

    const toggle = (field: string): void => {
        if (orderBy == null || field !== orderBy.field) {
            setOrderBy({field, dir: 'asc'});
            return;
        }

        if (orderBy.dir === 'asc') {
            setOrderBy({field, dir: 'desc'});
        } else {
            setOrderBy(initialValue);
        }
    }

    const orderClassnames = (field: string): any => {
        return {
            active: orderBy?.field == field,
            asc: orderBy?.field == field && orderBy.dir == 'asc',
            desc: orderBy?.field == field && orderBy.dir == 'desc',
        };
    };

    const restore = (orderBy: OrderBy|null) => {
        setOrderBy(orderBy);
    }

    return [orderBy, toggle, orderClassnames, restore]
}