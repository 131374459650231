import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import axios from '../api/axios';
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import WooOrderList from '../components/WooOrderList';
import { WooOrdersResponse } from '../@types/api-models';
import { useLocation } from 'react-router-dom';
import { useOrderBy } from '../hooks/OrderBy';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';

const WooOrders: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [ready, setReady] = useState<boolean | undefined>();
    const [offset, setOffset] = useState(0);
    const location = useLocation();
    const [orderBy, toggleOrderBy, orderByClassnames, restoreOrderBy] = useOrderBy({field: 'id', dir: 'desc'})
    const [downloadRange, setDownloadRange] = useState<DateValueType>(null); 
    const [unfilled, setUnfilled] = useState<boolean>(false);
        
    const onScroll = () => setOffset(window.scrollY);

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['woorders', currentPage],
        queryFn: async () => {

            const params = {
                _page: currentPage,
                _limit: ITEM_PER_PAGE,
                _order: orderBy == null ? undefined : orderBy.field + ':' + orderBy.dir,
                downloadedAt: downloadRange == null || downloadRange.startDate == null || downloadRange.endDate == null ?
                    undefined : {
                        gte: dayjs(downloadRange.startDate).toISOString(),
                        lte: dayjs(downloadRange.endDate).toISOString(),
                    },
                unfilled: unfilled ? true : undefined,
            };

            const response = await axios.get<WooOrdersResponse>(
                '/api/v1/wooorders', {
                    params,
                    paramsSerializer: {
                        indexes: true, // use brackets with indexes
                    }
                }
            );
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const orders = response.data.data;
            return { orders, total, perPage }
        },
        placeholderData: keepPreviousData,
        enabled: !!ready,

    });

    useEffect(() => {
        window.removeEventListener('scroll', onScroll);

        if (location.state) {
            const { currentPage, scrollPosition, orderBy, downloadRange, unfilled } = location.state;
            setCurrentPage(currentPage)
            restoreOrderBy(orderBy);
            setDownloadRange(downloadRange);
            setUnfilled(unfilled);
            setTimeout(() => {
                window.scrollTo(0, scrollPosition);
            }, (10));
        }

        setReady(true);
        window.addEventListener('scroll', onScroll, { passive: true });

        // clean up code
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    useEffect(() => {
        refetch();
    }, [orderBy])



    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Pedidos WooCommerce</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <div className="flex justify-end">
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text">Incompletas </span> 
                            <input type="checkbox" className="toggle toggle-sm" onChange={() => setUnfilled(!unfilled)} checked={unfilled} />
                        </label>
                    </div>
                    <Datepicker 
                        placeholder={"Seleccionar fechas"}
                        i18n={"es"} 
                        asSingle={false} 
                        useRange={false} 
                        showShortcuts={true} 
                        showFooter={false}
                        displayFormat={"DD/MM/YYYY"} 
                        value={downloadRange} 
                        onChange={setDownloadRange} 
                        inputClassName="input-sm w-64" 
                        containerClassName="relative" 
                        toggleClassName="btn-sm absolute bg-blue-300 rounded-r-lg text-white right-0 px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed" 

                        configs={{
                            shortcuts: {
                                today: "Hoy", 
                                yesterday: "Ayer",
                                past: period => `Ultimos ${period} días`,
                                currentMonth: "Este mes",
                                pastMonth: "Ultimo mes", 
                            },
                            footer: {
                                cancel: "Cancelar",
                                apply: "Aceptar" 
                            }
                        }} 
                        /> 
                        <div className="join">
                            <button onClick={() => {setCurrentPage(1); refetch()}}className="btn join-item btn-sm">Search</button>
                        </div>
                    </div>
                <WooOrderList 
                    orders={data? data.orders: []}
                    prevState={{currentPage, scrollPosition: offset, orderBy, downloadRange, unfilled}}
                    toggleOrderBy={toggleOrderBy}
                    orderByClassnames={orderByClassnames}
                    />
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default WooOrders
