import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import React, { useEffect, useState } from 'react'
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { Store, StoreProductsResponse } from '../@types/api-models';
import StoreProductList from '../components/StoreProductList';
import { getStores } from '../services.tsx/api';
import { OrderBy } from '../@types/shared-models';
import { useOrderBy } from '../hooks/OrderBy';


const StoreProducts: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [stores, setStores] = useState<Store[]>([]);
    const [storeFilter, setStoreFilter] = useState<string|undefined>();
    const [codeFilter, setCodeFilter] = useState<string|undefined>();
    const [nameFilter, setNameFilter] = useState<string|undefined>();
    const [orderBy, toggleOrderBy, orderByClassnames] = useOrderBy({field: 'id', dir: 'asc'})

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['storeProducts', currentPage],
        queryFn: async () => {
            const params = {
                _page: currentPage,
                _limit: ITEM_PER_PAGE,
                storeId: storeFilter,
                code: codeFilter == undefined ? undefined: {like: "%"+codeFilter+"%"},
                name: nameFilter == undefined ? undefined: {like: "%"+nameFilter+"%"},
                _order: orderBy == null ? undefined : orderBy.field + ':' + orderBy.dir,
            };

            const response = await axios.get<StoreProductsResponse>(
                '/api/v1/store-products',{
                    params,
                    paramsSerializer: {
                        indexes: true, // use brackets with indexes
                    }
                });
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const storeProducts = response.data.data;
            return { storeProducts, total, perPage }
        },
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        getStores().then(stores => setStores(stores));
    }, [])

    useEffect(() => {
        refetch();
    }, [orderBy])

    const searchByStore = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value == '') {
            setStoreFilter(undefined);
        } else {
            setStoreFilter(event.target.value);
        }
    };

    const searchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value == '') {
            setNameFilter(undefined);
        } else {
            setNameFilter(event.target.value);
        }
    };

    const searchByCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value == '') {
            setCodeFilter(undefined);
        } else {
            setCodeFilter(event.target.value);
        }
    };

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Productos</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                    <div className="flex justify-end">
                        <div className="join">
                            <input onChange={searchByCode} className="input input-bordered join-item input-sm" placeholder="Código"/>
                            <input onChange={searchByName} className="input input-bordered join-item input-sm" placeholder="Nombre"/>
                            <select defaultValue="" onChange={searchByStore} className="select select-bordered join-item select-sm">
                                <option key={0} value="">todos</option>
                                {stores.map(store => (
                                    
                                        <option key={store.id.toString()} value={store.id.toString()}>{store.name}</option>
                                    
                                ))}
                            </select>
                            <button onClick={() => {setCurrentPage(1); refetch()}}className="btn join-item btn-sm">Search</button>
                        </div>
                    </div>
                <StoreProductList storeProducts={data? data.storeProducts: []} toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames}/>
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default StoreProducts