import React, { useEffect, useState } from 'react'
import { CreateOrderItem, LaravelMeta, StoreProduct, WooOrder, WooOrderLine } from '../@types/api-models';
import { OrderLineInfo } from '../@types/shared-models';
import { createOrdersApi, getProductsBySkus } from '../services.tsx/api';
import { wooLineStatus, getWooRemainQuantity, getStoreNamesFromLines, StoreAndAlt }  from '../utils/orderutils'
import LoadingSpinner from './LoadingSpinner';
import InfoModal from './InfoModal';

interface CreateOrderFromWooProps {
    wooOrder: WooOrder,
    linesInfo: {[id: string]: OrderLineInfo[]; },
    onBack: (refresh?: boolean) => void,
}

interface OrderLinesToCreate {
    line: WooOrderLine,
    remainQuantity: number,
    productSelected?: number,
    altSelected?: string,
    priceSelected?: number,
    descSelected?: string,
}

const CreateOrderFromWoo: React.FC<CreateOrderFromWooProps> = ({wooOrder, linesInfo, onBack}) => {
    const [products, setProducts] = useState<{[id: string]: StoreProduct[]}>({});
    const [lines, setLines] = useState<OrderLinesToCreate[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>()
    const [showPreviousProviders, setShowPreviousProviders] = useState<OrderLineInfo[]|undefined>();
    const [skus, setSkus] = useState<{[id: string]: StoreAndAlt[]}>({});
    /**
     * Mostrar solo los productos que hayan fallado. Recorrer las lineas de la orden y 
     * comprobar con los lines info, si son anuladas, y entonces buscar
     */

    const getSkuAoptionClass = (sku: string, store: string, storeCode?: string) => {
        var inList = false;
        var stores = skus[sku]?? [];
        stores.forEach(element => {
            if (storeCode) {
                inList = inList || (element.store == store && element.alt == storeCode);
            } else {
                inList = inList || (element.store == store);
            }
        });

        return inList ? ' previous-order ' : ' no-previous-order '
    }

    const getProducts = async (skus: string[]) => {
        let chunk = skus.splice(0, 10);
        const productDict:{[id: string]: StoreProduct[]} = {};
        while (chunk.length > 0) {
            let currentPage = 1;
            while (true)  {
                const data = await getProductsBySkus(chunk, currentPage++);
                data.data.forEach((p: StoreProduct) => {
                    if (!(p.code in productDict)) {
                        productDict[p.code] = [];    
                    }
                    productDict[p.code].push(p);
                });

                const meta: LaravelMeta = data.meta;
                if (currentPage > meta.last_page) {
                    break;
                } 
            }
            chunk = skus.splice(0, 10);
        }
        setProducts(Object.assign(products, productDict));
    }

    const changeLineElement = (productId: string, sku: string, index: number) => {
        if (productId === '') {
            lines[index].descSelected = undefined;
            lines[index].priceSelected = undefined;
            lines[index].productSelected = undefined;
            lines[index].altSelected = undefined;
            setLines([...lines])   
        } else {
            var pieces = productId.split("#", 2);
            var alt: string|undefined = undefined;
            if (pieces.length > 1) {
                productId = pieces[0];
                alt = pieces[1];
            }
            products[sku].forEach(p => {
                if (p.id.toString() === productId) {
                    lines[index].descSelected = p.name;
                    lines[index].priceSelected = p.price;
                    lines[index].productSelected = p.id;     
                    lines[index].altSelected = alt;
                    setLines([...lines])   
                }
            });
        }
    }

    const createOrders = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        let items = lines.filter(line => line.productSelected !== undefined, false)
            .map((line): CreateOrderItem => {
                return {
                    productId: line.productSelected?? 0,
                    quantity: line.remainQuantity,
                    wooOrderLineId: line.line.id,
                    alt: line.altSelected,
                }});
        setIsFetching(true);
        createOrdersApi({items}).then(response => onBack(true))
            .catch(error => {
                console.log(error);
                setError(error.response.data.message ? error.response.data.message : error.message);
            })
            .finally(() => setIsFetching(false));
    }

    var axiosCalled = false;
    useEffect(() => {
        if (wooOrder.lines) {
            const skus: string[] = [];
            const lines: OrderLinesToCreate[] = [];
            const skusPrevStore: {[id: string]: StoreAndAlt[]} = {};
            wooOrder.lines.map(line => {
                skusPrevStore[line.sku] = getStoreNamesFromLines(linesInfo[line.id.toString()]);
                if (['fail', 'orig-fail'].includes(wooLineStatus(wooOrder, line, linesInfo[line.id.toString()]?? []))) {
                    skus.push(line.sku);
                    lines.push({
                        line,
                        remainQuantity: getWooRemainQuantity(line, linesInfo[line.id.toString()]?? [])
                    });
                }
            });
            setSkus(skusPrevStore);
            if (skus.length > 0) {
                setLines(lines);
                getProducts(skus).catch(ex => console.log(ex)).finally(() => setIsFetching(false));
            } else {
                setIsFetching(false);
            }
        }
    }, [])

    

    return (
        <>
            {showPreviousProviders && 
                <InfoModal id="previous-providers-modal" onOk={() => setShowPreviousProviders(undefined)} title='Proveedores anteriores'>
                    <table className="table table-xs">
                    <thead>
                        <tr>
                            <th>Proov.</th>
                            <th>Cant. orig.</th>
                            <th>Cant. pend.</th>
                            <th>Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showPreviousProviders.map(line => {
                            return (<tr key={line.line.id.toString()}>
                                <td>{line.order.storeName}</td>
                                <td>{line.line.quantity}</td>
                                <td>{line.line.remainQuantity?? 0}</td>
                                <td>{line.line.price}</td>
                            </tr>)
                        })}
                    </tbody>
                    </table>
                </InfoModal>
            }
            <div className="max-w-4xl mx-auto">
                <h1 className="text-center text-2xl my-5 underline font-bold">Pedido</h1>
                <div className="flex items-center justify-between my-5">
                    {isFetching ? <LoadingSpinner size={0} /> : <div></div>}
                    <div className="join join-vertical lg:join-horizontal gap-2">
                        <button
                            disabled={isFetching || !lines.reduce((state, v) => state || v.productSelected !== undefined , false)}
                            className="btn btn-info btn-xs"
                            onClick={createOrders}
                            >
                                Crear orden(es)
                            </button>

                        {onBack ?
                            <button disabled={isFetching} className="btn btn-info btn-xs"
                                onClick={() => onBack()}
                            >Go back</button>
                            : <div></div>
                        }
                    </div>
                </div>

                {error && <div className="bg-red-700 m-2 p-2 rounded text-white text-sm	">
                            {error}
                    </div>}

                <table className="table table-xs">
                    <thead>
                        <tr>
                            <th>store</th>
                            <th>sku</th>
                            <th>Desc.</th>
                            <th>Precio</th>
                            <th>Pedido a</th>
                            <th>Cant. orig.</th>
                            <th>Cant. pend.</th>
                            <th>Precio proov.</th>
                            <th>Desc. proov.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines ? lines.map((line, index) => (
                            <tr key={line.line.id.toString()}>
                                <td>
                                    <select
                                        disabled={isFetching}
                                        defaultValue=""
                                        onChange={(event) => changeLineElement(event.target.value, line.line.sku, index)}
                                        className="select select-bordered join-item select-sm">
                                        <option key={0} value="">no incluir</option>
                                        {products[line.line.sku] && products[line.line.sku].map(product => (
                                            <>
                                                <option className={getSkuAoptionClass(line.line.sku, product.storeName??'')} key={product.id.toString()} value={product.id.toString()}>{product.storeName}-{product.pvpMargin}({product.pvp})</option>
                                                {product.fedicomAlts && product.fedicomAlts.map(alt => (
                                                    <option  className={getSkuAoptionClass(line.line.sku, product.storeName??'', alt.code)} key={product.id.toString()+"#"+alt.code} value={product.id.toString()+"#"+alt.code}>{product.storeName}[{alt.description}]{product.pvpMargin}({product.pvp})</option>
                                                ))}
                                            </>
                                        ))}
                                    </select>
                                </td>
                                <td>{line.line.sku}</td>
                                <td>{line.line.name}</td>
                                <td>{line.line.price}</td>
                                <td>{linesInfo[line.line.id.toString()] && <>
                                    <button className="btn btn-info btn-xs"
                                        onClick={() => setShowPreviousProviders(linesInfo[line.line.id.toString()])}
                                    >ver</button>
                                </>}</td>
                                <td>{line.line.quantity}</td>
                                <td>{line.remainQuantity}</td>
                                <td>{line.priceSelected?? ''}</td>
                                <td>{line.descSelected?? ''}</td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CreateOrderFromWoo
