import React from 'react'
import { WooOrder } from '../@types/api-models'
import { generatePath, useNavigate } from 'react-router-dom'
import { StateWooOrderList } from '../@types/shared-models';
import { ThOrderBy } from './ThOrderBy';
import { formatDateUtc } from '../utils/datetime';


interface WooOrderProps {
    orders: WooOrder[],
    prevState: StateWooOrderList,
    toggleOrderBy: (field: string) => void,
    orderByClassnames: (field: string) => any,

}

const WooOrderList: React.FC<WooOrderProps> = ({ orders, prevState, toggleOrderBy, orderByClassnames }) => {
    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='id'>#</ThOrderBy>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='orderId'>Id original</ThOrderBy>
                        <th>Estado</th>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='downloadedAt'>Descargado</ThOrderBy>
                        <th>Actualizado</th>
                        <th>Error</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id.toString()}>
                            <th>{order.id.toString()}</th>
                            <td>{order.orderId}</td>
                            <td>{order.status}</td>
                            <td>{formatDateUtc(order.dateDownloaded)}</td>
                            <td>{formatDateUtc(order.updatedAt)}</td>
                            <td>{order.error}</td>
                            <td>
                                <button
                                    onClick={() => navigate(generatePath('/wooorders/:id', { id: order.id.toString() }), { state: prevState })}
                                    className="btn btn-ghost btn-xs">detalles
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default WooOrderList
