

export const formatDateUtc = (date?: Date|null) => {
    if (date === undefined || date == null) {
        return '';
    }

    const formatter = new Intl.DateTimeFormat('utc', {
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        year: '2-digit', month: '2-digit', day: '2-digit'
    });
    return formatter.format(date);
}