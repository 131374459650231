import React, { useEffect, useState } from 'react'
import useAuthContext from '../context/AuthContext';
import axios from '../api/axios';
import {isAxiosError} from 'axios';
import { IFotgotPasswordErrors } from '../@types/auth-context';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<IFotgotPasswordErrors>({});
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null);
    const { csrf } = useAuthContext();

    useEffect(() => {
        // I'll run when the component is mounted
        // Setting the initial state for this component
        return () => {
          // all the clean-ups related to this component
          // I'll run when the component is unmounted
            setEmail("");
            setErrors({});
            setError(null);
            setStatus(null);
        }
    
      }, []);

    const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        await csrf();
        setErrors({});
        setError(null);
        setStatus(null);
        try {
            const response = await axios.post('/api/forgot-password', {email});
            setStatus(response.data.message);
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                    setError(ex.response.data.message);
                }
            }
        }
    };


    return <section className="bg-[#F4F7FF] py-20 lg:py-[120px]">
        <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                <div
                    className="
                        relative
                        mx-auto
                        max-w-[525px]
                        overflow-hidden
                        rounded-lg
                        bg-white
                        py-16
                        px-10
                        text-center
                        sm:px-12
                        md:px-[60px]
                    "
                    >
                        {status && <div className="bg-green-700 m-2 p-2 rounded text-white">
                            {status}
                        </div>}

                        {error && <div className="bg-red-700 m-2 p-2 rounded text-white">
                            {error}
                        </div>}


                        <div className="mb-10 text-center md:mb-16">Forgot your password? Let us know your email address and we will email you a password reset link</div>
                        <form onSubmit={handleSubmit}
                            
                            
                        >
                            <div className="my-6">
                                <input
                                    type="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="
                                        block
                                        w-full
                                        rounded-lg
                                        border border-gray-300
                                        bg-gray-50
                                        p-2.5
                                        text-sm text-gray-900
                                        focus:border-blue-500 focus:ring-blue-500
                                        dark:border-gray-600
                                        dark:bg-gray-700
                                        dark:text-white
                                        dark:placeholder-gray-400
                                        dark:focus:border-blue-500
                                        dark:focus:ring-blue-500
                                    "
                                    placeholder="email@luaterra.com"
                                />
                                 { errors.email &&<div className="flex">
                                    <span className="text-red-400 text-sm m-2 p-2">{errors.email[0]}</span>
                                </div>}
                            </div>
                            <button
                                type="submit"
                                className="
                                w-full
                                px-4
                                py-3
                                bg-indigo-500
                                hover:bg-indigo-700
                                rounded-md
                                text-white
                                "
                            >
                                Submit
                            </button>
                        </form>
                    
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default ForgotPassword
