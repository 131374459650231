import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import React, { useState } from 'react'
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { ReportsResponse } from '../@types/api-models';
import ReportList from '../components/ReportList';
import { isAxiosError } from 'axios';
import { ICreateReportErrors } from '../@types/api-errors';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';


const Reports: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [reportType, setReportType] = useState("order-summary");
    const [errors, setErrors] = useState<ICreateReportErrors>({});
    const [downloadRange, setDownloadRange] = useState<DateValueType>(null); 

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['reports', currentPage],
        queryFn: async () => {
            const response = await axios.get<ReportsResponse>(`/api/v1/reports?_page=${currentPage}&_limit=${ITEM_PER_PAGE}`);
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const reports = response.data.data;
            return { reports, total, perPage }
        },
        placeholderData: keepPreviousData,
    });

    const handleCreateReport = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors({});
        try {
            await axios.post('/api/v1/reports', {
                reportType,
                reportFrom: downloadRange == null || downloadRange.startDate == null ?
                    undefined : dayjs(downloadRange.startDate).toISOString(),
                reportTo: downloadRange == null || downloadRange.endDate == null ?
                    undefined : dayjs(downloadRange.endDate).toISOString(),
            });
            refetch();
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                }
            }
        }
    }

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Informes</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <div className="flex items-center justify-between my-5">
                    <div></div>
                    <div className="join">
                        <form onSubmit={handleCreateReport}>
                            <select onChange={(e) => setReportType(e.target.value)} className="select select-bordered join-item select-sm">
                                <option value="order-summary">Resumen de órdenes</option>
                            </select>
                            <Datepicker 
                                placeholder={"Seleccionar fechas"}
                                i18n={"es"} 
                                asSingle={false} 
                                useRange={false} 
                                showShortcuts={true} 
                                showFooter={false}
                                displayFormat={"DD/MM/YYYY"} 
                                value={downloadRange} 
                                onChange={setDownloadRange} 
                                inputClassName="input-sm w-64" 
                                containerClassName="relative" 
                                toggleClassName="btn-sm absolute bg-blue-300 rounded-r-lg text-white right-0 px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed" 

                                configs={{
                                    shortcuts: {
                                        today: "Hoy", 
                                        yesterday: "Ayer",
                                        past: period => `Ultimos ${period} días`,
                                        currentMonth: "Este mes",
                                        pastMonth: "Ultimo mes", 
                                    },
                                    footer: {
                                        cancel: "Cancelar",
                                        apply: "Aceptar" 
                                    }
                                }} 
                                />
                            <button type="submit" className="btn join-item btn-sm">Crear report</button>
                            
                            
                        </form>
                    </div>
                    
                </div>
                {(errors.error || errors.reportFrom || errors.reportTo || errors.reportType) && 
                                <div className="flex items-center justify-between my-5"><div></div>
                                    <div className="join"><div className="relative">
                                    { errors.error &&<span className="text-red-400 text-sm block">{errors.error[0]}</span>}
                                    { errors.reportType &&<span className="text-red-400 text-sm block">{errors.reportType[0]}</span>}
                                    { errors.reportFrom &&<span className="text-red-400 text-sm block">{errors.reportFrom[0]}</span>}
                                    { errors.reportTo &&<span className="text-red-400 text-sm block">{errors.reportTo[0]}</span>}
                                    </div></div>
                                </div>
                            }
                <ReportList reports={data? data.reports: []} refetch={refetch}/>
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default Reports