import React from 'react'
import { StoreProduct } from '../@types/api-models'
import { OrderBy } from '../@types/shared-models'
import classnames from 'classnames';
import { ThOrderBy } from './ThOrderBy';
import { formatDateUtc } from '../utils/datetime';


interface StoreProductListProps {
    storeProducts: StoreProduct[],
    toggleOrderBy: (field: string) => void,
    orderByClassnames: (field: string) => any,
}

const StoreProductList: React.FC<StoreProductListProps> = ({ storeProducts, toggleOrderBy, orderByClassnames }) => {

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='id'>#</ThOrderBy>
                        <ThOrderBy toggleOrderBy={toggleOrderBy} orderByClassnames={orderByClassnames} field='code'>Código</ThOrderBy>
                        <th>Nombre</th>
                        <th>Creado</th>
                        <th>Actualizado</th>
                        <th>Iva</th>
                        <th>Precio</th>
                        <th>PVP</th>
                        <th>Almacén</th>
                    </tr>
                </thead>
                <tbody>
                    {storeProducts.map(storeProduct => (
                        <tr key={storeProduct.id.toString()}>
                            <th>{storeProduct.id.toString()}</th>
                            <td>{storeProduct.code}</td>
                            <td>{storeProduct.name}</td>
                            <td>{formatDateUtc(storeProduct.createdAt)}</td>
                            <td>{formatDateUtc(storeProduct.updatedAt)}</td>
                            <td>{storeProduct.iva}</td>
                            <td>{storeProduct.price}</td>
                            <td>{storeProduct.pvp}</td>
                            <td>{storeProduct.storeName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StoreProductList
