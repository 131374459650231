import { useEffect, useState } from 'react'
import { Link, useSearchParams} from 'react-router-dom'
import useAuthContext from '../context/AuthContext';

const Register = () => {
    const [invitationToken, setInvitationToken] = useState<string|null>(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const { register, errors, error, status, resetStatus } = useAuthContext();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // I'll run when the component is mounted
        // Setting the initial state for this component
        setInvitationToken(searchParams.get('invitation_token'));

        return () => {
          // all the clean-ups related to this component
          // I'll run when the component is unmounted
            setInvitationToken(null);
            setEmail("");
            setPassword("");
            setPasswordConfirmation("");
            resetStatus();
        }
    
      }, []);

    const handleRegister = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        register({name, email, password, password_confirmation, invitation_token: invitationToken});
    }

    return <section className="bg-[#F4F7FF] py-20 lg:py-[120px]">
        <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
                <div
                className="
                    relative
                    mx-auto
                    max-w-[525px]
                    overflow-hidden
                    rounded-lg
                    bg-white
                    py-16
                    px-10
                    text-center
                    sm:px-12
                    md:px-[60px]
                "
                >
                <div className="mb-10 text-center md:mb-16">Luaterra Pedidos</div>
                {status && <div className="bg-green-700 m-2 p-2 rounded text-white">
                            {status}
                            <div className='m-2 p-2'>
                                Goto <Link to="/login">Login</Link>
                            </div>
                        </div>}

                {error && <div className="bg-red-700 m-2 p-2 rounded text-white">
                            {error}
                    </div>}
                { errors.invitation_token &&
                        <div className="flex">
                            <span className="text-red-400 text-sm m-2 p-2">{errors.invitation_token[0]}</span>
                </div>}
                <form onSubmit={handleRegister}>
                    <div className="mb-6">
                        <input
                            type="text"
                            value={ name }
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="
                            bordder-[#E9EDF4]
                            w-full
                            rounded-md
                            border
                            bg-[#FCFDFE]
                            py-3
                            px-5
                            text-base text-body-color
                            placeholder-[#ACB6BE]
                            outline-none
                            focus:border-primary
                            focus-visible:shadow-none
                            "
                        />
                        { errors.name &&
                        <div className="flex">
                            <span className="text-red-400 text-sm m-2 p-2">{errors.name[0]}</span>
                        </div>}
                    </div>
                    <div className="mb-6">
                        <input
                            type="email"
                            value={ email }
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="
                            bordder-[#E9EDF4]
                            w-full
                            rounded-md
                            border
                            bg-[#FCFDFE]
                            py-3
                            px-5
                            text-base text-body-color
                            placeholder-[#ACB6BE]
                            outline-none
                            focus:border-primary
                            focus-visible:shadow-none
                            "
                        />
                        { errors.email &&
                        <div className="flex">
                            <span className="text-red-400 text-sm m-2 p-2">{errors.email[0]}</span>
                        </div>}
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Password"
                            value={ password }
                            onChange={(e) => setPassword(e.target.value)}
                            className="
                            bordder-[#E9EDF4]
                            w-full
                            rounded-md
                            border
                            bg-[#FCFDFE]
                            py-3
                            px-5
                            text-base text-body-color
                            placeholder-[#ACB6BE]
                            outline-none
                            focus:border-primary
                            focus-visible:shadow-none
                            "
                        />
                        { errors.password &&
                        <div className="flex">
                            <span className="text-red-400 text-sm m-2 p-2">{errors.password[0]}</span>
                        </div>}
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Password Confirmation"
                            value={ password_confirmation }
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            className="
                            bordder-[#E9EDF4]
                            w-full
                            rounded-md
                            border
                            bg-[#FCFDFE]
                            py-3
                            px-5
                            text-base text-body-color
                            placeholder-[#ACB6BE]
                            outline-none
                            focus:border-primary
                            focus-visible:shadow-none
                            "
                        />
                        { errors.password_confirmation &&
                        <div className="flex">
                            <span className="text-red-400 text-sm m-2 p-2">{errors.password_confirmation[0]}</span>
                        </div>}
                    </div>
                    <div className="mb-10">
                        <button
                            type="submit"
                            className="
                            w-full
                            px-4
                            py-3
                            bg-indigo-500
                            hover:bg-indigo-700
                            rounded-md
                            text-white
                            "
                        >
                            Register
                        </button>
                        
                    </div>
                </form>
                <p className="text-base text-[#adadad]">
                    <Link to="/login" className="text-primary hover:underline">
                    Sign In
                    </Link>
                </p>
                </div>
            </div>
            </div>
        </div>
        </section>
}

export default Register
