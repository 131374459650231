import React from 'react'
import { User } from '../@types/api-models'
import ConfirmModal from './ConfirmModal';
import axios from '../api/axios';
import { formatDateUtc } from '../utils/datetime';


interface UserListProps {
    users: User[],
}

const UserList: React.FC<UserListProps> = ({ users }) => {
    
    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Registrado</th>
                        <th>Roles</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id.toString()}>
                            <th>{user.id.toString()}</th>
                            <td>{user.email}</td>
                            <td>{user.name}</td>
                            <td>{formatDateUtc(user.createdAt)}</td>
                            <td>{user.roles?.map(role => (
                                <span>{role.name}</span>
                            ))}</td>
                            <td>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default UserList
