import axios from "axios";
import { parseISO } from "date-fns";

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?[Z|z]?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost",
  withCredentials: true,
  withXSRFToken: true,
  
});

client.interceptors.response.use((rep) => {
  handleDates(rep.data);
  return rep;
});

client.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      if (error.response && error.response.status === 401) {
          // Use router.push() to navigate to the login screen
          // router.push('/login'); // Adjust the route as needed
          window.location.assign('/login');
          // Throw an exception to stop further execution
          return Promise.reject('Unauthorized');
      }
      // Handle other errors here
      return Promise.reject(error);
  }
);

export default client;