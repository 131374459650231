import * as React from "react";

// icon:clock-fill | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
export function IconClockFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zM8 3.5a.5.5 0 00-1 0V9a.5.5 0 00.252.434l3.5 2a.5.5 0 00.496-.868L8 8.71V3.5z" />
    </svg>
  );
}

// icon:tick | Typicons https://www.s-ings.com/typicons/ | Stephen Hutchings
export function IconTick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      baseProfile="tiny"
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M16.972 6.251a1.999 1.999 0 00-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 10-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 001.471-1.009l5-9a2 2 0 00-.776-2.72z" />
    </svg>
  );
}

// icon:cancel | Entypo http://entypo.com/ | Daniel Bruce
export function IconCancel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 470 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M452 656c12 12 18 26.333 18 43s-6 31-18 43c-12 10.667-26.333 16-43 16s-31-5.333-43-16L234 590 102 742c-12 10.667-26.333 16-43 16s-31-5.333-43-16C5.333 730 0 715.667 0 699s5.333-31 16-43l138-156L16 342C5.333 330 0 315.667 0 299s5.333-31 16-43c12-10.667 26.333-16 43-16s31 5.333 43 16l132 152 132-152c12-10.667 26.333-16 43-16s31 5.333 43 16c12 12 18 26.333 18 43s-6 31-18 43L314 500l138 156" />
    </svg>
  );
}