import React from 'react'

interface IPaginationParameters {
  currentPage: number,
  totalItems: number,
  itemsPerPage: number,
  onPageChange: (page: number) => void,
  isPlaceholderData: boolean
}

const Pagination: React.FC<IPaginationParameters> = ({currentPage , totalItems, itemsPerPage, onPageChange, isPlaceholderData} ) => {
  const pageCount = Math.ceil(totalItems/itemsPerPage);

  const handlePrevClick = () => onPageChange(currentPage - 1);
  const handleNextClick = () => onPageChange(currentPage + 1);

  const handlePrev10Click = () => onPageChange(currentPage < 10 ? 1 : currentPage - 10);
  const handleNext10Click = () => onPageChange(currentPage > pageCount + 10 ? pageCount : currentPage + 10);

  return (
    <div>
        {pageCount > 1 && <div className="join">
            <button disabled={currentPage === 1 || isPlaceholderData} onClick={handlePrev10Click} className="join-item btn btn-sm">««</button>
            <button disabled={currentPage === 1 || isPlaceholderData} onClick={handlePrevClick} className="join-item btn btn-sm">«</button>
            <button className="join-item btn btn-sm">Page {currentPage}</button>
            <button disabled={currentPage === pageCount || isPlaceholderData} onClick={handleNextClick} className="join-item btn btn-sm">»</button>
            <button disabled={currentPage === pageCount || isPlaceholderData} onClick={handleNext10Click} className="join-item btn btn-sm">»»</button>
        </div>}
    </div>
  )
}

export default Pagination
