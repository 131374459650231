import React from 'react'

interface ConfirmModalProps {
  id: string,
  title?: string,
  message?: string,
  onOk?: () => void,
  onCancel?: () => void,
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({id, title="", message="", onOk=()=>{}, onCancel=()=>{}}) => {
  return (
    <dialog id={id} className="modal" open={true}>
        <div className="modal-box">
            <h3 className="font-bold text-lg">{title}!</h3>
            <p className="py-4">{message}</p>
            <div className="modal-action">
              <button onClick={onOk} className="btn">Aceptar</button>
              <button onClick={onCancel} className="btn">Close</button>
            </div>
        </div>
    </dialog>
  )
}

export default ConfirmModal
