import React from 'react'
import useAuthContext from '../context/AuthContext'
import { Link, Navigate, Outlet } from 'react-router-dom'

const AuthLayout: React.FC = () => {
    const { user, logout } = useAuthContext();

    const handleClick = () => {
        const elem = document.activeElement as HTMLElement;
        if (elem) {
          elem?.blur();
        }
    };

    return( 
        <>
        {(() => {
             if (user === undefined)    {
                return <div className="skeleton w-full h-32"></div>;
             }

             if (user === null) {
                return <Navigate to="/login" />;
             }

             return (
                <>
                    <div className="navbar bg-indigo-900 text-white ">
                        <div className="flex-1">
                            <a className="btn btn-ghost text-xl">Luaterra Pedidos</a>
                        </div>
                        <div className="flex-none gap-2">
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost">
                                <Link to="/">Home</Link>
                                </div>
                            </div>

                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost">
                                <Link to="/wooorders">Woocommerce</Link>
                                </div>
                            </div>
                            
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost">
                                    <Link to="/orders">Orders</Link>
                                </div>
                            </div>

                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost">
                                    <Link to="/store-products">Productos</Link>
                                </div>
                            </div>
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost">
                                <Link to="/reports">Informes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex-none gap-2">
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
                                </div>
                                <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-indigo-600 rounded-box w-52">
                                    <li onClick={handleClick}><Link to="/users">Usuarios</Link></li>
                                    <li onClick={handleClick}><Link to="/invitations">Invitaciones</Link></li>
                                </ul>
                            </div>

                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2"/></svg>
                                </div>
                                <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-indigo-600 rounded-box w-52">
                                    <li onClick={handleClick}><Link to="/store-ftps">Sync Ftp</Link></li>
                                    <li onClick={handleClick}><Link to="/store-internals">Sync Luafarma</Link></li>
                                    <li onClick={handleClick}><Link to="/service-statuses">Servicios</Link></li>
                                </ul>
                            </div>


                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                                    <div className="w-10 rounded-full">
                                    <img alt="Tailwind CSS Navbar component" src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                                    </div>
                                </div>
                                <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-indigo-600 rounded-box w-52">
                                    <li onClick={handleClick}>
                                        <a className="justify-between">
                                            Profile
                                            <span className="badge">New</span>
                                        </a>
                                    </li>
                                    <li onClick={handleClick}><a>Settings</a></li>
                                    <li onClick={handleClick}><a onClick={logout}>Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>


                  
                <Outlet/> 
                </>
            )
        })()}

        </>
    )
}

export default AuthLayout
