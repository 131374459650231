import React from 'react'

interface TabularDataPropsInfo {
  label: string,
  value?: string,
  type?: string,
  required?: boolean,
}

interface TabularDataProps {
  info: TabularDataPropsInfo[],
  maxCols?: number
}

const TabularData: React.FC<TabularDataProps> = ({info, maxCols}) => {
    const lgCols = [
        'lg:grid-cols-1',
        'lg:grid-cols-1',
        'lg:grid-cols-2',
        'lg:grid-cols-3'
    ];

    const lgColspan = [
        'lg:col-span-1',
        'lg:col-span-1',
        'lg:col-span-2',
        'lg:col-span-3'
    ];

    return (
        <div className={`grid px-2 py-2 card bg-base-300 rounded-box 
            ${maxCols != undefined && maxCols < 4 ?lgCols[maxCols]:'lg:grid-cols-4'}
            ${maxCols != undefined && maxCols < 2 ?'md:grid-cols-1':'md:grid-cols-2'}
            sm:grid-cols-1 gap-2 text-xs`}>
            {info.map((item, i) => {
                
                if (item.value || item.required) {
                    if (item.type === 'textarea') {
                        return (
                            <div key={i} className={`px-2
                                ${maxCols != undefined && maxCols < 4 ?lgColspan[maxCols]:'lg:col-span-4'}
                                ${maxCols != undefined && maxCols < 2 ?'md:col-span-1':'md:col-span-2'}
                                `}>
                                <span className='font-bold'>{item.label}</span><br/>
                                <span className="flex bg-[white] break-all">{item.value? item.value:''}</span>
                            </div>
                        );
                    }
                    return (
                        <div key={i} className="px-2">
                            <div className={`flex flex-row`}>
                                <div className="font-bold basis-3/6">{item.label}</div>
                                <div className="basis-3/6 bg-[white] break-all">{item.value? item.value:''}</div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>

        
    )
}

export default TabularData
