import React from 'react'
import { StoreInternal } from '../@types/api-models'
import { formatDateUtc } from '../utils/datetime'


interface StoreInternalListProps {
    storeInternals: StoreInternal[],
}

const StoreFtpList: React.FC<StoreInternalListProps> = ({ storeInternals }) => {

    return (
        <div className="overflow-x-auto">
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Almacén</th>
                        <th>Estado</th>
                        <th>Ultima ejec.</th>
                        <th>Ultima ejec. OK</th>
                        <th>Googlesheet</th>
                    </tr>
                </thead>
                <tbody>
                    {storeInternals.map(storeInternal => (
                        <tr key={storeInternal.id.toString()}>
                            <th>{storeInternal.id.toString()}</th>
                            <td>{storeInternal.storeName}</td>
                            <td>{storeInternal.status}</td>
                            <td>{formatDateUtc(storeInternal.executedAt)}</td>
                            <td>{formatDateUtc(storeInternal.lastSuccessExecutionAt)}</td>
                            <td>{storeInternal.googleSheetId}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StoreFtpList
