import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../api/axios';
import React, { useState } from 'react'
import { ITEM_PER_PAGE } from '../utils/constants';
import Pagination from '../components/Pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { InvitationsResponse } from '../@types/api-models';
import InvitationList from '../components/InvitationList';
import { isAxiosError } from 'axios';
import { ICreateInvitationErrors } from '../@types/api-errors';


const Invitations: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<ICreateInvitationErrors>({});

    const { isLoading, data, isError, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['invitations', currentPage],
        queryFn: async () => {
            const response = await axios.get<InvitationsResponse>(`/api/v1/invitations?_page=${currentPage}&_limit=${ITEM_PER_PAGE}`);
            const total = response.data.meta.total;
            const perPage = response.data.meta.per_page;
            const invitations = response.data.data;
            return { invitations, total, perPage }
        },
        placeholderData: keepPreviousData,
    });

    const handleCreateInvitation = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors({});
        try {
            await axios.post('/api/v1/invitations', {email});
            setEmail("");
            refetch();
        } catch(ex: any) {
            if (isAxiosError(ex) && ex.response){
                if (ex.response.status === 422) {
                    setErrors(ex.response.data.errors);
                }
            }
        }
    }

    if (isError) return <>`Error ${error.message}`</>;

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Invitaciones</h1>
            {isLoading ? (
                <div className="flex flex-col gap-4">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-32 w-full"></div>
                </div>
            ) : (
                <>
                <div className="flex items-center justify-between my-5">
                    <div></div>
                    <div className="join">
                        <form onSubmit={handleCreateInvitation}>
                            <input onChange={(e) => setEmail(e.target.value)} className="input input-bordered join-item input-xs" placeholder="email invitación" value={email}/>
                            <button type="submit" className="btn join-item btn-sm">Crear invitación</button>
                            { errors.email &&<div className="flex">
                                <span className="text-red-400 text-sm m-2 p-2">{errors.email[0]}</span>
                                    </div>}
                        </form>
                    </div>
                </div>
                <InvitationList invitations={data? data.invitations: []} refetch={refetch}/>
                <div className="flex items-center justify-between my-5">
                    <Pagination
                        currentPage={currentPage}
                        totalItems={data? data.total : 0}
                        itemsPerPage={data? data.perPage : 0}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        isPlaceholderData={isPlaceholderData}
                    />
                    {isFetching ? <LoadingSpinner /> : null}
                </div>
                </>
            )}
        </div>
    )
}

export default Invitations