import React, { useState } from 'react'
import { OrderLineInfo } from '../@types/shared-models'
import { Order, OrderLineIssue, WooOrderLine } from '../@types/api-models'
import { IconCancel, IconClockFill, IconTick } from './icons/icons'
import CodeModal from './CodeModal'
import OrderComponent from './OrderComponent'

interface OrderLinesFromWooOrderLineProps {
    wooLine: WooOrderLine,
    linesInfo: OrderLineInfo[],
    onBack?: () => void,
}

const OrderLinesFromWooOrderLine: React.FC<OrderLinesFromWooOrderLineProps> = ({wooLine, linesInfo, onBack}) => {
    const [orderLineIssues, setOrderLineIssues] = useState<OrderLineIssue[]|undefined>();
    const [showOrder, setShowOrder] = useState<Order|null>(null);

    const onShowOrder = (order: Order) => {
        setShowOrder(order)
    }

    const onHideOrder = () => {
        setShowOrder(null)
    }

    return (
        <>
        { orderLineIssues && <CodeModal
                    id="OrderLineIssues"
                    title="Notificationes"
                    code={JSON.stringify(orderLineIssues, null, 2)}
                    onOk={() => { setOrderLineIssues(undefined) }} />
                }

        { showOrder && <OrderComponent isFetching={false} orderInfo={showOrder} onBack={onHideOrder}/>}

        { !showOrder && <div className="max-w-4xl mx-auto">
            <h1 className="text-center text-2xl my-5 underline font-bold">Líneas:</h1>
            <div className="flex items-center justify-between my-5">
                <div></div>
                {onBack ?
                    <button className="btn btn-info btn-xs"
                        onClick={onBack}
                    >Go back</button>
                    : <div></div>
                }
            </div>
            <table className="table table-xs">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Prov.</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>O-Estado</th>
                        <th>O-Proov.</th>
                        <th>O-Código</th>
                        <th>O-Descripción</th>
                        <th>O-Cantidad</th>
                        <th>O-Cantidad pend.</th>
                        <th>o-Precio</th>
                        <th></th>
                </tr>
                </thead>
                <tbody>
                    {linesInfo.map(line => (
                        <tr key={line.line.id.toString()}>
                            <th>{wooLine.sku}</th>
                            <th>{wooLine.providerName}</th>
                            <td>{wooLine.name}</td>
                            <td>{wooLine.quantity}</td>
                            <td>{wooLine.price}</td>
                            <td>{line.order.status}</td>
                            <td>{line.order.storeName}</td>
                            <td>{line.line.code}</td>
                            <td>{line.line.description}</td>
                            <td>{line.line.quantity}</td>
                            <td>{line.line.remainQuantity}</td>
                            <td>{line.line.price}</td>
                            <td>
                                <div className='flex items-center'>
                                    {(() => {
                                        // fedicom order still pending -> PENDING
                                        if (['created', 'pending'].includes(line.order.status)){
                                            return <IconClockFill fill="#fcc419" />;
                                        }

                                        if (['error'].includes(line.order.status)){
                                            return <IconCancel fill="red"/>;
                                        }

                                        // fedicom order finished and no remain quantity -> OK
                                        if (line.line.remainQuantity == null || line.line.remainQuantity == 0) {
                                            return <IconTick fill="#20C997"/>;
                                        }
                                        // remain quantity == line quantity -> FAIL
                                        if (line.line.remainQuantity != null && line.line.quantity == line.line.remainQuantity) {
                                            return <IconCancel fill="red"/>;
                                        }
                                        // else -> FAIL but some units buyed
                                        return <IconCancel fill="#fcc419"/>;
                                    })()}
                                    <div className="join">
                                        <button
                                            onClick={() => onShowOrder(line.order)}
                                            className="btn btn-ghost btn-xs">orden
                                        </button>
                                        {line.line.issues && line.line.issues.length > 0 &&
                                        <button onClick={() => setOrderLineIssues(line.line.issues)} className="btn btn-ghost btn-xs">
                                            notif
                                        </button>}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        }
    </>
  )
}

export default OrderLinesFromWooOrderLine
